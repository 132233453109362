// components
import Container from "../Containers/Container";

// assets
import ExpandImage1 from "assets/images/expand-1.png";
import ExpandImage2 from "assets/images/expand-2.png";
import ExpandImage3 from "assets/images/expand-3.png";
import ExpandImage4 from "assets/images/expand-4.png";
import ExpandImage5 from "assets/images/expand-5.png";
import ExpandImage6 from "assets/images/expand-6.png";
import ExpandImage7 from "assets/images/expand-7.png";
import ExpandImage8 from "assets/images/expand-8.png";

interface IProps {
  ToggleContactUsModalHandler: () => void;
}

export default function Exapnd(props: IProps) {
  // tracking contact modal
  const { ToggleContactUsModalHandler } = {
    ...props,
  };

  return (
    <Container>
      <div
        id="link"
        className="pt-[5.5rem] 2xl:pt-[6.5rem] px-4 lg:px-10 2xl:px-20 xxl-1900:px-40 flex flex-col lg:flex-row items-start xl:items-center gap-10 2xl:gap-20 xxl-1900:gap-28"
      >
        <div className="lg:max-w-[420px] xl:max-w-[520px]">
          <h2 className="text-3xl md:text-[2.625rem] font-bold text-primary-dark leading-none uppercase whitespace-pre-line">
            Expand your work
          </h2>
          <p className="mt-5 text-base text-primary-dark font-normal capitalize whitespace-pre-line">
            {
              "Ready for a partnership that's built to last? Look no further! We'll be your trusty sidekick from discovery to finished product. We're all about thorough dedication to your complete satisfaction and our goal is to deliver a solution that's optimized just for you. Whether it's modernizing or expanding your current applications or custom development through a complete life-cycle, we've got you covered. So, let's make it happen together!\n\nPlease Contact Us For Any Of Your Software Needs."
            }
          </p>
          <button
            onClick={ToggleContactUsModalHandler}
            className="mt-8 w-full sm:w-fit py-5 px-7 text-sm lg:text-base font-bold text-[#F5F5F5] uppercase bg-primary transition-colors hover:bg-primary-dark"
          >
            Explore Complementary Products
          </button>
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <div className="relative">
              <img
                src={ExpandImage5}
                alt="..."
                className="absolute inset-0 aspect-[6/4] z-[-1]"
              />
              <img
                src={ExpandImage1}
                alt="..."
                className="aspect-[6/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
            <div className="relative">
              <img
                src={ExpandImage6}
                alt="..."
                className="absolute inset-0 aspect-[6/4] z-[-1]"
              />
              <img
                src={ExpandImage2}
                alt="..."
                className="aspect-[6/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
          </div>
          <div className="flex">
            <div className="relative">
              <img
                src={ExpandImage7}
                alt="..."
                className="absolute inset-0 aspect-[6/4] z-[-1]"
              />
              <img
                src={ExpandImage3}
                alt="..."
                className="aspect-[6/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
            <div className="relative">
              <img
                src={ExpandImage8}
                alt="..."
                className="absolute inset-0 aspect-[6/4] z-[-1]"
              />
              <img
                src={ExpandImage4}
                alt="..."
                className="aspect-[6/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
