import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

interface PaypalComponentProps {
  amount: number;
  _PaypalPaymentDone: () => void;
}

const PaypalComponent = (props: PaypalComponentProps) => {
  const { amount, _PaypalPaymentDone } = { ...props };
  const initialOptions = {
    "client-id":
      "AYWe9FiEemczpE_4zh32p37Oa27Qg5UpPQaIMMIzv-_prz78y2yOFbMFyaXVUPmxoo-a4MfAwJretbyK",
    "enable-funding": "venmo",
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: `${amount}`,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions: any) => {
          return actions.order.capture().then((details: any) => {
            // console.log("First********");
            // const name = details.payer.name.given_name;
            // alert(`Transaction completed by ${name}`);
            // console.log("Second*****");
            _PaypalPaymentDone();
          });
        }}
      />
    </PayPalScriptProvider>
  );
};
export default PaypalComponent;
