import * as React from "react";

function Phone(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M.509 1.345L3.357.658A.633.633 0 013.8.715a.679.679 0 01.308.34L5.42 4.264c.056.137.068.289.034.434a.69.69 0 01-.223.367l-1.66 1.42c1.003 2.23 2.716 4.024 4.845 5.075l1.356-1.74a.653.653 0 01.351-.232.629.629 0 01.414.035l3.064 1.374a.68.68 0 01.326.324c.07.145.09.31.056.468l-.656 2.982a.692.692 0 01-.231.383.637.637 0 01-.409.15c-1.666 0-3.316-.344-4.856-1.011a12.665 12.665 0 01-4.116-2.88A13.345 13.345 0 01.966 7.1 13.834 13.834 0 010 2.016c0-.156.05-.307.142-.429a.653.653 0 01.367-.242z"
        fill="#E20613"
      />
    </svg>
  );
}

export default Phone;
