import React, { useState, useEffect } from "react";
import styles from "./assets/sass/modal.module.sass";

interface ModalComponentProps {
  open: boolean;
  errorMessage: string;
  CancelClickHandler: () => void;
  SubmitClickHandler: (pInputValue: string) => void;
  invoiceNumber: string;
}

const ModalComponent: React.FC<ModalComponentProps> = (props) => {
  const { open, errorMessage, CancelClickHandler, SubmitClickHandler, invoiceNumber: propInvoiceNumber } = props;

  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(propInvoiceNumber || "");
  const [invoiceNotValid, setInvoiceNotValid] = useState(false);

  useEffect(() => {
    setLocalInvoiceNumber(propInvoiceNumber || "");
    setInvoiceNotValid(false); 
  }, [propInvoiceNumber]);

  const _OnInvoiceChangeHandler = (evt: any) => {
    const inputValue = evt.currentTarget.value.trim();

    if (inputValue.length < 10 || inputValue.length > 25 || !inputValue.startsWith("G")) {
      setInvoiceNotValid(true);
    } else {
      setInvoiceNotValid(false);
    }

    setLocalInvoiceNumber(inputValue.toUpperCase());
  };

  return (
    <>
      <div className={`cursor-default relative ${open ? "block" : "hidden"}`}>
        <div
          className="inset-0 w-full  flex align-middle justify-center items-center"
          style={{ height: "calc(100vh - 64px)" }}
        >
          <div className="flex items-center justify-center align-middle p-4 text-center transition-all duration-300 ease-out">
            <div
              className={`${styles.container} lg:h-64 md:w-4/6 md:h-72 sm:h-64 xs:h-64 shrink-0 fixed flex flex-col inset-0 align-middle  m-auto justify-center items-center text-base transform overflow-hidden bg-gray-form p-6 pb-0 pt-0 text-center shadow-xl transition-all`}
              style={
                open
                  ? { opacity: 1, transform: "scale(1)" }
                  : { opacity: 0, transform: "scale(0.95)" }
              }
            >
              <h3 className="text-2xl font-semibold leading-10 mt-0 text-white">
                Welcome to GISimplify
              </h3>
              {errorMessage && <br />}
              <div
                className="flex flex-col items-start justify-start mx-auto lg:mt-5 md:mt-5 sm:mt-3 lg:w-3/4 md:w-3/4 sm:w-full xs:w-full text-center"
                style={{ height: "5.625rem" }}
              >
                <p className="text-xsm font-normal leading-4 pl-4 mt-1 text-gray-label text-left">
                  • Please enter your invoice number :
                </p>
                <input
                  placeholder="GABCD1234X"
                  className={`${invoiceNotValid ? styles.inputError : styles.input
                    } bg-gray-input-bck shadow-custom max-w-full mt-3 border-current pl-2 pr-12 w-full h-10 focus:outline-custom-gray-outline focus:ring-gray-100  sm:text-sm font-medium text-white`}
                  onChange={_OnInvoiceChangeHandler}
                  value={localInvoiceNumber}
                />
                {invoiceNotValid && (
                  <span className={styles.helper}>
                    Invalid Invoice Number
                  </span>
                )}
              </div>
              <div
                className={`${styles.buttons} w-full flex items-center justify-center mx-auto mb-0 lg:mt-10 md:mt-10 sm:mt-4`}
              >
                <button
                  type="button"
                  className={`${styles.button} w-32 h-7 shadow-md flex items-center justify-center border border-custom-red-button bg-custom-red-button mr-3 px-6 py-1 text-sm font-normal text-white hover:text-xsm hover:font-normal hover:bg-custom-red hover:border-2 hover:border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-100 focus-visible:ring-offset-2`}
                  onClick={() => {
                    if (
                      localInvoiceNumber &&
                      localInvoiceNumber.length >= 10 &&
                      localInvoiceNumber.startsWith("G")
                    ) {
                      SubmitClickHandler(localInvoiceNumber);
                    } else {
                      setInvoiceNotValid(true);
                    }
                  }}
                >
                  Go
                </button>
                <button
                  className={`${styles.button} w-32 h-7 shadow-md flex items-center justify-center border border-custom-red-button bg-custom-red-button px-6 py-1 text-sm font-normal text-white hover:text-xsm hover:font-normal hover:bg-custom-red hover:border-2 hover:border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-100 focus-visible:ring-offset-2`}
                  onClick={CancelClickHandler}
                >
                  Cancel
                </button>
              </div>
              {errorMessage && (
                <div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {errorMessage}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
