import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Billing from "pages/Billing/Billing";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import Home from "./pages/Home/Home";
import Privacy from "pages/privacy/Privacy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/billpay" element={<Billing />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
