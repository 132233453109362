import * as React from "react";

function Facebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 11 11" fill="none" {...props}>
      <path
        d="M6.606 10.466v-3.48h1.18l.219-1.44H6.6v-.934c0-.394.197-.778.826-.778h.639V2.61a7.883 7.883 0 00-1.134-.098c-1.158 0-1.912.69-1.912 1.933v1.097H3.738v1.44h1.286v3.484a5.088 5.088 0 01-3.059-1.685A4.933 4.933 0 01.751 5.546c0-.654.131-1.302.386-1.906a4.974 4.974 0 011.097-1.615A5.069 5.069 0 013.877.945a5.137 5.137 0 015.519 1.08c.47.462.843 1.011 1.097 1.615.255.604.386 1.252.385 1.906 0 1.186-.43 2.333-1.213 3.235a5.088 5.088 0 01-3.06 1.685z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Facebook;
