import * as React from "react";
import styles from "./assetes/css/Privacy.module.scss";
import { Fragment, useState } from "react";
import classnames from "classnames";
import { BurgerMenuIcon, LogoIcon, XIcon } from "assets/icons";
import Navbar from "../../components/Containers/Navbar";
import Footer from "components/Footer/Footer";
import Contact from "components/Contact/Contact";
import { NOTIFICATION } from "config/config";
import Notification from "components/Contact/Notification";



const Privacy = ( ) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [notification, setNotification] = useState<NOTIFICATION>({
    severity: "success",
    message: "",
    open: false,
  });

  const _ToggleContactUsModalHandler = () => {
    setIsContactModalOpen(!isContactModalOpen);
  };
  const _SetNotificationHandler = (details: NOTIFICATION) => {
    setNotification(details);
  };

  return (
    <>
<div className={styles.ni}>
        <div className="flex w-full">
          <div className="flex-grow">
            <Navbar /> 
          </div>
        </div>
        </div>

        <div style={{backgroundColor:"white"}} className="px-14">

      <div className={styles.zz}>

      <section className={styles.sec1}>
        <div className={styles.container}>
          <div>
            <h1 className={styles.he}>Privacy Policy</h1>
            <span>
              <span className={styles.one}>Last updated:</span>
              <span className={styles.two}>November</span>{" "}
              <span className={styles.three}>09, 2023 </span>{" "}
            </span>
            <p className={styles.para}>

              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You
              <br /> use the Service and tells You about Your privacy rights and
              how the law protects You.
            </p>

            <p className={styles.para}>

              {" "}
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </p>
           
                <hr className={styles.wl} color=" #333" />
          </div>
        </div>
      </section>

      <section>
        <div className={styles.container}>
          <div>
            <h2 className={styles.twot}>
Interpretation and Definitions</h2>
            <h3 className={styles.tree}>
Interpretation</h3>
            <p className={styles.para}>

              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>

            <h3 className={styles.tree}>
Definitions</h3>
            <h5 className={styles.five}>
For the purposes of this Privacy Policy:</h5>
            <div>
              <h6 className={styles.six}>

                <strong>Account</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                means a unique account created for You to access our Service or
                parts of our Service.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Affiliate</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                means an entity that controls, is controlled by or is under
                common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities
                entitled to vote for election of directors or other managing
                authority.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Company</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                (referred to as either "the Company", "We", "Us" or "Our" in
                this Agreement) refers to GISimplify, 5944 HIGHWAY 92 STE 100
                ACWORTH, GA 30102-9998.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Cookies</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                are small files that are placed on Your computer, mobile device
                or any other device by a website, containing the details of Your
                browsing history on that website among its many uses.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Country</strong>
              </h6>
              <p className={styles.para}>
 refers to: Georgia, United States</p>
              <br />
              <h6 className={styles.six}>

                <strong>Device</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                means any device that can access the Service such as a computer,
                a cellphone or a digital tablet.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Personal Data</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                is any information that relates to an identified or identifiable
                individual. It refers to third-party companies or individuals
                employed by the Company to facilitate the Service, to provide
                the Service on behalf of the Company, to perform services
                related to the Service or to assist the Company in analyzing how
                the Service is used.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Service</strong>
              </h6>
              <p className={styles.para}>
 refers to the Website.</p>
              <br />
              <h6 className={styles.six}>

                <strong>Service Provider</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                means any natural or legal person who processes the data on
                behalf of the Company.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Usage Data</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                refers to data collected automatically, either generated by the
                use of the Service or from the Service infrastructure itself
                (for example, the duration of a page visit)
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>Website</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                refers to GISimplify, accessible from{" "}
                <a href="https://gisimplify.com/privacy.html">
                  https://gisimplify.com/privacy.html
                </a>
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>You</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </p>
            </div>

              <hr className={styles.wl} color="#333" />
          </div>
        </div>
      </section>

      <section>
        <div className={styles.container}>
          <div>
            <h2 className={styles.twot}>Collecting and Using Your Personal Data</h2>
            <h3 className={styles.tree}>
Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p className={styles.para}>

              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <h6 className={styles.six}>
Email address</h6> <br />
            <h6 className={styles.six}>
First name and last name</h6><br />
            <h6 className={styles.six}>
Phone number </h6><br />
            <h6 className={styles.six}>
 Address, State, Province, ZIP/Postal code, City </h6><br />
            <h6 className={styles.six}>
Usage Data</h6><br />
            <h3 className={styles.tree}>
Usage Data</h3>
            <p className={styles.para}>

              Usage Data is collected automatically when using the Service.
              <br />
              <br />
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
              <br /> <br /> When You access the Service by or through a mobile
              device, We may collect certain information automatically,
              including, but not limited to, the type of mobile device You use,
              Your mobile device unique ID, the IP address of Your mobile
              device, Your mobile operating system, the type of mobile Internet
              browser You use, unique device identifiers and other diagnostic
              data.
              <br /> <br />
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
              <br />
            </p>
            <h3 className={styles.tree}>
Tracking Technologies and Cookies </h3>
            <p className={styles.para}>

              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <h6 className={styles.six}>
Cookies or Browser Cookies.</h6>
            <p className={styles.para}>

              A cookie is a small file placed on Your Device. You can instruct
              Your browser to refuse all Cookies or to indicate when a Cookie is
              being sent. However, if You do not accept Cookies, You may not be
              able to use some parts of our Service. Unless you have adjusted
              Your browser setting so that it will refuse Cookies, our Service
              may use Cookies.
            </p>
            <h6 className={styles.six}>
Web Beacons.</h6>
            <p className={styles.para}>

              Certain sections of our Service and our emails may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit the Company,
              for example, to count users who have visited those pages or opened
              an email and for other related website statistics (for example,
              recording the popularity of a certain section and verifying system
              and server integrity).
            </p>
            <p className={styles.para}>

              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser.
            </p>
            <h5 className={styles.five}>

              We use both Session and Persistent Cookies for the purposes set
              out below:
            </h5>
            <h3 className={styles.tree}>
Necessary / Essential Cookies</h3>
            <p className={styles.para}>
Type: Session Cookies</p>
            <p className={styles.para}>
Administered by: Us</p>
            <p className={styles.para}>

              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
            <h3 className={styles.tree}>
Cookies Policy / Notice Acceptance Cookies</h3>
            <p className={styles.para}>
Type: Persistent Cookies</p>
            <p className={styles.para}>
Administered by: Us</p>
            <p className={styles.para}>

              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
            <h3 className={styles.tree}>
Functionality Cookies</h3>
            <p className={styles.para}>
Type: Persistent Cookies</p>
            <p className={styles.para}>
Administered by: Us</p>
            <p className={styles.para}>

              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
            <p className={styles.para}>

              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
            <h3 className={styles.tree}>
Use of Your Personal Data</h3>
            <h5 className={styles.five}>

              The Company may use Personal Data for the following purposes:
            </h5>
            <div>
              <h6 className={styles.six}>

                <strong>To provide and maintain our Service,</strong>
              </h6>
              <p className={styles.para}>
 including to monitor the usage of our Service.</p>
              <br />
              <h6 className={styles.six}>

                <strong>To manage Your Account:</strong>
              </h6>
              <p className={styles.para}>

                to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.{" "}
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>For the performance of a contract:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                the development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service.{" "}
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>To contact You:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>To provide You </strong>
              </h6>
              <p className={styles.para}>

                {" "}
                with news, special offers and general information about other
                goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless
                You have opted not to receive such information.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>To manage Your requests:</strong>
              </h6>
              <p className={styles.para}>
 To attend and manage Your requests to Us. </p>
              <br />
              <h6 className={styles.six}>

                <strong>For business transfers:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>For other purposes:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </p>
              <br />
            </div>

            <h3 className={styles.tree}>

              We may share Your personal information in the followin situations:
            </h3>
            <div>
              <h6 className={styles.six}>

                <strong>With Service Providers:</strong>
              </h6>
              <p className={styles.para}>

                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>For business transfers:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>With Affiliates:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                We may share Your information with Our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </p>
              <br />
              <h6 className={styles.six}>

                <strong>With business partners:</strong>
              </h6>
              <p className={styles.para}>

                {" "}
                when You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </p>
              <h6 className={styles.six}>

                <strong>With Your consent:</strong>
              </h6>
              <p className={styles.para}>

                We may disclose Your personal information for any other purpose
                with Your consent.
              </p>
            </div>

            <h3 className={styles.tree}>
Retention of Your Personal Data</h3>
            <p className={styles.para}>

              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
              <br />
              <br />
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>

            <h3 className={styles.tree}>
Transfer of Your Personal Data </h3>
            <p className={styles.para}>

              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to â€” and maintained on â€”
              computers located outside of Your state, province, country or
              other governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
              <br />
              <br />
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
              <br />
              <br />
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>

            <h3 className={styles.tree}>
Delete Your Personal Data </h3>
            <p className={styles.para}>

              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
              <br />
              <br />
              Our Service may give You the ability to delete certain information
              about You from within the Service.
              <br />
              <br />
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
              <br />
              <br />
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>

            <h5 className={styles.five}>
Disclosure of Your Personal Data</h5>
            <br />
            <br />
            <h6 className={styles.six}>

              <strong>Business Transactions</strong>
            </h6>
            <p className={styles.para}>

              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <br />
            <h6 className={styles.six}>

              <strong>Law enforcement</strong>
            </h6>
            <p className={styles.para}>

              {" "}
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <br />
          </div>
        </div>
      </section>

      <section>
        <div className={styles.container}>
          <div>
            <h3 className={styles.tree}>
Other legal requirements</h3>
            <h5 className={styles.five}>

              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </h5>
            <br />
            <br />
            <h6 className={styles.six}>
Comply with a legal obligation</h6> <br />
            <h6 className={styles.six}>
Protect and defend the rights or property of the Company</h6><br />
            <h6 className={styles.six}>

              Prevent or investigate possible wrongdoing in connection with the
              Service
            </h6><br />
            <h6 className={styles.six}>

              Protect the personal safety of Users of the Service or the public
            </h6><br />
            <h6 className={styles.six}>
Protect against legal liability</h6><br />
            <h5 className={styles.five}>
Security of Your Personal Data</h5>
            <p className={styles.para}>

              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.back}>
        <div className={styles.container}>
          <div>
            <h3 className={styles.tree}>
Children's Privacy</h3>
            <p className={styles.para}>

              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
              <br />
              <br />
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>

            <h3 className={styles.tree}>
Links to Other Websites</h3>
            <p className={styles.para}>

              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
              <br />
              <br />
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h3 className={styles.tree}>
Changes to this Privacy Policy</h3>
            <p className={styles.para}>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
              <br />
              <br />
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
              <br />
              <br />
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <h3 className={styles.tree}>
Contact Us</h3>
            <p className={styles.para}>

              If you have any questions about this Privacy Policy, You can
              contact us:
              <br />
              By email: info@GISimplify.com
              <br />
              <br />

            </p>
          </div>
        </div>
      </section>
      </div>
{/* <div className={styles.zoka}>
      <div   className="mt-11 px-4 sm:px-0 flex flex-wrap items-center justify-center gap-6 ">
          <a
            href="#en"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            English (Global)
          </a>
          <a
            href="https://gisimplify.com/#services"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Our services
          </a>
          <a
            href="https://gisimplify.com/#portfolio"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Portfolio
          </a>
          <a
            href="https://gisimplify.com/#link"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Links
          </a>
          <a
            href="Privacy"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Privacy
          </a>
          <a
            href="https://gisimplify.com/#contact"
            className="text-base font-medium text-white cursor-pointer transition-colors hover:text-primary hover:underline"
            // onClick={ToggleContactUsModalHandler}
          >
            Contact Us
          </a>
        </div>
<div className={styles.te}>
        <p className="mt-6 px-4 sm:px-0 text-base font-medium text-white">
          © Copyright 2022. All Rights Reserved. GiSimplify
        </p>
        </div>
        </div> */}
 <Footer ToggleContactUsModalHandler={_ToggleContactUsModalHandler} />
        {isContactModalOpen && (
          <Contact
            ToggleContactUsModalHandler={_ToggleContactUsModalHandler}
            setNotificationHandler={_SetNotificationHandler}
          />
        )}
        {notification.open && (
          <Notification
            notificationDetails={notification}
            handleClose={() =>
              setNotification({ message: "", severity: "success", open: false })
            }
          />
        )}          </div>
    </>
  );
};

export default Privacy;
