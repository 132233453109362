// react
import { useEffect } from "react";
// config
import { NOTIFICATION } from "config/config";

interface NotificationProps {
  notificationDetails: NOTIFICATION;
  handleClose: () => void;
}
const Notification = ({
  notificationDetails,
  handleClose,
}: NotificationProps) => {
  const handleCloseToast = () => {
    handleClose();
  };
  useEffect(() => {
    if (notificationDetails.open === true) {
      const timeoutId = setTimeout(() => {
        handleClose();
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [notificationDetails.open]);

  return (
    <>
      {notificationDetails.open &&
        (notificationDetails.severity === "error" ? (
          <div
            className="z-9999 fixed bottom-1 left-1/2 transform -translate-x-1/2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
            role="alert"
          >
            <span className="block sm:inline text-sm mr-9">
              {notificationDetails.message}
            </span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className="fill-current h-5 w-5 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                onClick={handleCloseToast}
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        ) : (
          <div
            className="z-9999 fixed bottom-1 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded"
            role="alert"
          >
            <span className="block sm:inline text-sm mr-9">
              {notificationDetails.message}
            </span>
            <span className="absolute bottom-0 right-0 px-4 py-3">
              <svg
                className="fill-current h-5 w-5 text-green-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                onClick={handleCloseToast}
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        ))}

      {/* <Snackbar
        className={styles.snackbar}
        open={notificationDetails.open}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        key={"bottom" + "center"}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity={notificationDetails.severity}
          sx={{ width: "100%" }}
        >
          {notificationDetails.message}
        </Alert>
      </Snackbar> */}
    </>
  );
};

export default Notification;
