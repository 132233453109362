import classnames from "classnames";

// hooks
import useImageTransition from "hooks/useImageTransition";

// assests
import HeroOne from "assets/images/hero-1.jpeg";
import HeroTwo from "assets/images/hero-2.jpeg";

const images = [HeroOne, HeroTwo];

export default function Hero() {
  const [currentImage, currentIndex] = useImageTransition(images);

  return (
    <div className="relative">
      <div
        className={classnames(
          "relative z-10 bg-no-repeat bg-cover bg-center aspect-[3/4] sm:aspect-[16/9] lg:aspect-[21/9]"
        )}
        style={{
          backgroundImage:
            currentIndex === 0
              ? `url(${currentImage})`
              : `linear-gradient(0deg, rgba(0, 0, 0, 0.71), rgba(0, 0, 0, 0.71)), url(${currentImage})`,
        }}
      />
      <div className="absolute inset-0 z-20 flex flex-col justify-center px-4 sm:px-8 md:pr-0 md:pl-16 lg:pl-36">
        <h2 className="text-white text-base sm:text-2xl lg:text-3xl leading-none uppercase font-medium">
          Connect people, locations, & data using
        </h2>
        <h1 className="text-primary uppercase text-3xl sm:text-6xl lg:text-7xl leading-none font-bold">
          interactive maps
        </h1>
        <p className="mt-3 max-w-[718px] text-white text-sm sm:text-base lg:text-lg font-normal capitalize">
          Say hello to GISimplify, the ultimate map-making sidekick! Our web
          tool makes it easy for anyone to do map-related tasks. Right now,
          we're starting with the basics - But
          don't worry, we've got more tricks up our sleeve and we're always
          adding new functionalities. So why wait? Come join the map-making fun
          with GISimplify!
        </p>
        <a href="/Geo/" target="_blank" rel="noreferrer">
          <button className="mt-8 w-fit py-5 px-7 text-sm lg:text-base font-bold text-[#F5F5F5] uppercase bg-primary transition-colors hover:bg-primary-dark">
            Access Our Tools
          </button>
        </a>
      </div>
    </div>
  );
}
