import * as React from "react";

function BurgerMenuIcon(props) {
  return (
    <svg
      width={28}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.333 18.007c0 .733-.594 1.326-1.326 1.326h-10.68a1.327 1.327 0 110-2.653h10.68c.733 0 1.326.593 1.326 1.327zM27.333 10c0 .733-.595 1.327-1.326 1.327H1.992a1.327 1.327 0 010-2.654h24.014c.733 0 1.326.595 1.326 1.327zM26.006 3.32a1.327 1.327 0 100-2.653H9.993a1.327 1.327 0 100 2.653h16.014z"
        fill="#1D1D1B"
      />
    </svg>
  );
}

export default BurgerMenuIcon;
