import { useState, useEffect } from "react";

export default function useImageTransition(images: string[]) {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let currentIndex = 0;

    const intervalId = window.setInterval(() => {
      if (currentIndex < images.length - 1) {
        currentIndex += 1;
      } else {
        currentIndex = 0;
      }

      setCurrentImage(images[currentIndex]);
      setCurrentIndex(currentIndex);
    }, 5000);

    return () => window.clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [currentImage, currentIndex];
}
