import classnames from "classnames";

// components
import Container  from "../Containers/Container";

// assets
import { InteractiveWebIcon } from "assets/icons";
import WebMap1 from "assets/images/web-map-1.png";
import WebMap2 from "assets/images/web-map-2.png";

export default function WebMaps() {
  return (
    <Container>
      <div className="relative overflow-hidden">
        <InteractiveWebIcon className="w-[313px] h-[25px] md:w-[616px] md:h-[50px] absolute -top-1 -right-3" />

        <div className="pt-[5.5rem] 2xl:pt-[6.5rem] px-4 md:px-0 flex flex-col md:flex-row items-center gap-16 xl:gap-24 2xl:gap-32">
          <div className="w-full md:w-1/2 flex-shrink-0">
            <div
              className={classnames(
                "relative",
                "after:contents[''] after:absolute after:right-[-1rem] after:top-1/2 after:-translate-y-1/2 after:h-2/3 after:w-8 after:bg-primary after:z-[-2]"
              )}
            >
              <img
                src={WebMap2}
                alt="..."
                className="w-full absolute inset-0 aspect-[16/9] md:aspect-[3/4] lg:aspect-[16/9] z-[-1]"
              />
              <img
                src={WebMap1}
                alt="..."
                className="w-full aspect-[16/9] md:aspect-[3/4] lg:aspect-[16/9] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 max-w-[720px]">
            <h3 className="uppercase text-xl 2xl:text-[26px] leading-none font-medium text-primary-dark">
              Cloud-based software to create & share
            </h3>
            <h2 className="uppercase text-5xl 2xl:text-[55px] leading-none font-bold text-primary">
              interactive web maps
            </h2>
            <p className="mt-7 text-base text-primary-dark font-normal">
              Data analysis got you feeling bland? We'll add some flavor! Our
              boundary-pushing methods deliver insights that are not only
              meaningful and efficient but also robust. Let's turn your data
              into valuable information and drive your business to success.
              <br />
              <br />
              Are you ready to take your brilliant idea on a wild ride from
              concept to reality? Look no further! We're the ultimate
              idea-evolving machine, taking you from the first spark of
              inspiration all the way to a fully-fledged, successful product.
              Whether you're looking for small tweaks or a complete enterprise
              deployment, we've got you covered. So hold on tight, and let's
              turn that idea into a reality that will knock your socks off!
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
