import React from "react";
import classnames from "classnames";

interface IProps {
  children: React.ReactNode;
  wrapperClassname?: String;
}

export default function Container({ children, wrapperClassname }: IProps) {
  return (
    <div
      className={classnames(
        "max-w-[2000px] mx-auto",
        wrapperClassname as string
      )}
    >
      {children}
    </div>
  );
}
