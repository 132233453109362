import * as React from "react";

function ArrowLeft(props) {
 return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
     <path
        d="M10.921 1.243a1.015 1.015 0 01-.016 1.46l-5.11 4.883h12.186a1.013 1.013 0 01.942.63c.051.123.077.256.077.39v1.36a1.024 1.024 0 01-.63.945 1.012 1.012 0 01-.39.076H5.796l5.11 4.883a1.022 1.022 0 01.016 1.46l-.94.943a1.013 1.013 0 01-1.439 0L.299 10.005a1.016 1.016 0 010-1.44L8.542.3a1.014 1.014 0 011.439 0l.94.943z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowLeft;
