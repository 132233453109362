// assets
import {
  PhoneIcon,
  MailIcon,
  FacebookIcon,
  TwitterIcon,
  YoutubeIcon,
} from "assets/icons";

export default function TopBanner() {
  return (
    <div className="flex">
      <div className="hidden md:min-w-[16%]" />
      <div className="flex-grow flex flex-col-reverse md:flex-row gap-2.5 md:gap-0 items-center justify-between py-3 md:pl-9 bg-[#EDEDEDB2] bg-opacity-70">
        <p className="hidden md:block text-xs text-[#8F8F8C]">
          Welcome to GISimplify - ESRI Bronze Partener
        </p>

        <div className="w-full md:w-auto flex items-center justify-center md:justify-start gap-4 pl-4 md:pl-0 pr-4 min-[1380px]:pr-20">
          <a
            href="tel: +1470-504-3852"
            className="flex items-center gap-1 cursor-pointer"
          >
            <PhoneIcon />
            <span className="text-xs text-[#8F8F8C]">+1 (470) 504-3852</span>
          </a>
          <a
            href="mailto:Info@GISimplify.com"
            className="flex  items-center gap-1 cursor-pointer"
          >
            <MailIcon />
            <span className="text-xs text-[#8F8F8C]">Info@GISimplify.com</span>
          </a>
        </div>

        <div className="flex md:hidden align-center gap-2.5">
          <span className="w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors text-primary-dark  border-[.5px] border-primary-dark hover:bg-primary hover:text-white hover:border-primary">
            <a
              href="https://www.facebook.com/GISimplify"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FacebookIcon />
            </a>
          </span>
          <span className="w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors text-primary-dark border-[.5px] border-primary-dark hover:bg-primary hover:text-white hover:border-primary">
            <a
              href="https://twitter.com/GISimplify"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <TwitterIcon />
            </a>
          </span>
          <span className="w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors text-primary-dark  border-[.5px] border-primary-dark hover:bg-primary hover:text-white hover:border-primary">
            <a
              href="https://www.youtube.com/@GISimplify"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <YoutubeIcon />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
