import { Tab } from "@headlessui/react";
import PaypalComponent from "../Payal/Paypal";
import BankDetail from "../BankDetail/BankDetail";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface paymentPanelProps {
  amount: number;
  _PaypalPaymentReceived: () => void;
}
const PaymentPanel = (props: paymentPanelProps) => {
  const { amount, _PaypalPaymentReceived } = { ...props };

  const _PaypalPaymentDone = () => {
    _PaypalPaymentReceived();
  };
  return (
    <div className="w-full max-w-md px-2 py-16 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 z-50 relative">
          <Tab
            key={"Paypal"}
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow "
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            Credi, Debit Card, or Paypal
          </Tab>
          <Tab
            key={"WireTransfer"}
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            International Wire Transfer
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel
            key={"Paypal"}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            {amount > 0 && (
              <PaypalComponent
                _PaypalPaymentDone={_PaypalPaymentDone}
                amount={amount}
              />
            )}
          </Tab.Panel>
          <Tab.Panel
            key={"WireTransfer"}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
            )}
          >
            <BankDetail />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
export default PaymentPanel;
