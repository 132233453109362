interface CompanyDetailComponentProps {
  companyName: string;
  amount: number;
  dueDate: string;
}

const CompanyDetailComponent = (props: CompanyDetailComponentProps) => {
  const { companyName, amount, dueDate } = { ...props };
  return (
    <div style={{ marginTop: "5vh" }}>
      <div>
        <span style={{ fontWeight: "bold" }}> Bill to:</span>{" "}
        <span style={{ fontSize: "13px" }}> {companyName}</span>
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}> Amount:</span>{" "}
        <span style={{ fontSize: "13px" }}> {amount}$</span>
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}> Due Date:</span>{" "}
        <span style={{ fontSize: "13px" }}> {dueDate}</span>
      </div>
    </div>
  );
};
export default CompanyDetailComponent;
