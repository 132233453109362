// react
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
// components
import Navbar from "components/Containers/Navbar";
import PaymentPanel from "components/PaymentPanel/PaymentPanel";
import ModalComponent from "components/Modal/Modal";
import CompanyDetailComponent from "components/CompanyDetail/CompanyDetail";
import Footer from "components/Footer/Footer";
import Contact from "components/Contact/Contact";
import Notification from "components/Contact/Notification";
// config
import { getIp } from "config/helper";
import hmacSHA512 from "crypto-js/hmac-sha512";
import { TOKEN } from "config/config";
import { NOTIFICATION } from "config/config";
import { addCaptchaToLocalStorage } from "config/helper";
// assets
import styles from "./assets/scss/Billing.module.sass";

interface BillingProps {}

const Billing: React.FC<BillingProps> = () => {
  const navigate = useNavigate();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [notification, setNotification] = useState<NOTIFICATION>({
    severity: "success",
    message: "",
    open: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState({
    amount: -1,
    company: "",
    dueDate: "",
  });

  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code");

  const [invoiceNumber, setInvoiceNumber] = useState(codeParam || "");

  useEffect(() => {
    console.log("Code parameter from URL:", codeParam);

    if (codeParam) {
      console.log("Setting invoice number:", codeParam);
      setInvoiceNumber(codeParam);
    }
  }, [codeParam]);

  console.log("Current invoice number:", invoiceNumber);

  const _ToggleContactUsModalHandler = () => {
    setIsContactModalOpen(!isContactModalOpen);
  };

  const _SetNotificationHandler = (details: NOTIFICATION) => {
    setNotification(details);
  };

  const _CancelClickHandler = () => {
    navigate("/");
  };

  const signRequest = (
    url: string,
    method: string = "POST",
    timestamp: string
  ) => {
    const secretKey = TOKEN;
    const signature = hmacSHA512(url + method + timestamp, secretKey);
    return signature.toString();
  };

  const _GetInvoiceHandler = async (invoiceNumber: string) => {
    const timestamp = new Date().toISOString();
    const endpointUrl = "gisimplify/billing";
    const apiURL = process.env.REACT_APP_API_URL || "";
    const signature = signRequest("/" + endpointUrl, "POST", timestamp);

    const ip = await getIp();

    const formData = new FormData();
    formData.append("invoiceNumber", invoiceNumber);
    formData.append("ip", ip);

    axios
      .post(apiURL + endpointUrl, formData, {
        headers: {
          "X-Signature": signature,
          "X-Timestamp": timestamp,
        },
      })
      .then(function (response) {
        if (response.data.verification_status) {
          if (response.data.captcha) {
            addCaptchaToLocalStorage(response.data.captcha);
            window.location.href = "../Geo/verify";
          }
        }
        if (response && response.data && response.data.code === 200) {
          setInvoiceDetails({
            amount: response.data.data.amount,
            company: response.data.data.company,
            dueDate: response.data.data.dueDate,
          });
        } else if (response && response.data && response.data.code === 404) {
          setErrorMessage("Invalid Invoice Number");
        }
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage("Invalid Invoice Number");
      });
  };

  const _PaypalPaymentReceived = () => {
    setInvoiceDetails((prevInvoiceDetails) => {
      return { ...prevInvoiceDetails, amount: 0 };
    });
  };

  const handleSubmission = (inputInvoiceNumber: string) => {
    _GetInvoiceHandler(inputInvoiceNumber);
  };

  return (
    <div className={styles.billingbackground}>
      <Navbar />
      <ModalComponent
        open={!invoiceDetails.company}
        SubmitClickHandler={handleSubmission}
        errorMessage={errorMessage}
        CancelClickHandler={_CancelClickHandler}
        invoiceNumber={invoiceNumber}
      />

      {invoiceDetails.company && (
        <>
          <CompanyDetailComponent
            companyName={invoiceDetails.company}
            amount={invoiceDetails.amount}
            dueDate={invoiceDetails.dueDate}
          />
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "6vh",
              position: "relative",
              zIndex: "2",
            }}
          >
            {invoiceDetails.amount > 0 ? (
              <PaymentPanel
                _PaypalPaymentReceived={_PaypalPaymentReceived}
                amount={invoiceDetails.amount}
              />
            ) : (
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  color: "green",
                  fontSize: "11px",
                  minHeight: "5vh",
                  height: "auto",
                  width: "45vw",
                  borderRadius: "5px",
                }}
              >
                <span style={{ color: "green", fontSize: "11px" }}>
                  Thank you for your payment. Your transaction has been
                  completed, and a receipt for your purchase has been emailed to
                  you. Log into your PayPal account to view transaction details.
                </span>{" "}
              </div>
            )}
          </div>
        </>
      )}
      <Footer ToggleContactUsModalHandler={_ToggleContactUsModalHandler} />
      {isContactModalOpen && (
        <Contact
          ToggleContactUsModalHandler={_ToggleContactUsModalHandler}
          setNotificationHandler={_SetNotificationHandler}
        />
      )}
      {notification.open && (
        <Notification
          notificationDetails={notification}
          handleClose={() =>
            setNotification({ message: "", severity: "success", open: false })
          }
        />
      )}
    </div>
  );
};

export default Billing;
