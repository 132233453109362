import * as React from "react";

function ArrowRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M8.079 17.33a1.015 1.015 0 01.017-1.46l5.109-4.883H1.019a1.013 1.013 0 01-.942-.63A1.021 1.021 0 010 9.968v-1.36a1.021 1.021 0 01.63-.944c.123-.051.255-.077.39-.077h12.185l-5.11-4.883a1.022 1.022 0 01-.016-1.46L9.019.3a1.014 1.014 0 011.439 0L18.7 8.568a1.016 1.016 0 010 1.441l-8.243 8.264a1.014 1.014 0 01-1.439 0l-.94-.943z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowRight;
