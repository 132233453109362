import * as React from "react";

function Mail(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 15" fill="none" {...props}>
      <path
        d="M9.003 9.764c-.816.014-1.982-1.04-2.58-1.475C1.76 4.858 1.404 4.558.323 3.703A.85.85 0 010 3.028v-.677c0-.454.178-.889.494-1.21.316-.32.745-.5 1.193-.5h14.62c.448 0 .876.18 1.193.501.316.32.494.756.494 1.209v.677a.867.867 0 01-.323.675c-1.068.852-1.43 1.155-6.1 4.586-.58.436-1.752 1.49-2.568 1.475zM.346 5.16c.789.619 1.83 1.412 5.414 4.053.713.528 1.982 1.701 3.243 1.701s2.5-1.147 3.24-1.7c3.585-2.642 4.629-3.429 5.415-4.05a.209.209 0 01.31.054.215.215 0 01.032.114v7.285c0 .454-.178.889-.494 1.21-.316.32-.745.5-1.193.5H1.693c-.448 0-.877-.18-1.193-.5a1.723 1.723 0 01-.494-1.21V5.328a.217.217 0 01.12-.19.21.21 0 01.22.023v-.002z"
        fill="#E20613"
      />
    </svg>
  );
}


export default Mail;
