import { useEffect, useState } from "react";
// components
import TopBanner from "../../components/TopBanner/TopBanner";
import Navbar from "../../components/Containers/Navbar";
import Footer from "../../components/Footer/Footer";
import Cloud from "../../components/HomeContent/Cloud";
import CTA from "../../components/HomeContent/CTA";
import Hero from "../../components/HomeContent/Hero";
import Portfolio from "../../components/HomeContent/Portfolio";
import Services from "../../components/HomeContent/Services";
import Sidebar from "../../components/HomeContent/Sidebar";
import WebMaps from "../../components/HomeContent/WebMaps";
import Expand from "../../components/HomeContent/Exapnd";
import Clients from "../../components/HomeContent/Clients";
import Contact from "components/Contact/Contact";
import { NOTIFICATION } from "config/config";
import Notification from "components/Contact/Notification";

export default function Home() {
  // tracking contact modal
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [notification, setNotification] = useState<NOTIFICATION>({
    severity: "success",
    message: "",
    open: false,
  });

  const _ToggleContactUsModalHandler = () => {
    setIsContactModalOpen(!isContactModalOpen);
  };
  const _SetNotificationHandler = (details: NOTIFICATION) => {
    setNotification(details);
  };
  useEffect(() => {
    if (window.location.hash) {
      const targetElement = document.querySelector(window.location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <>
      <div>
        <TopBanner />

        <div className="flex w-full">
          <Sidebar />
          <div className="flex-grow">
            <Navbar />
            <Hero />
          </div>
        </div>
        <WebMaps />
        <Services />
        <CTA />
        <Cloud ToggleContactUsModalHandler={_ToggleContactUsModalHandler} />
        <Portfolio />
        <Expand ToggleContactUsModalHandler={_ToggleContactUsModalHandler} />
        {/* <Clients /> */}
        <Footer ToggleContactUsModalHandler={_ToggleContactUsModalHandler} />
        {isContactModalOpen && (
          <Contact
            ToggleContactUsModalHandler={_ToggleContactUsModalHandler}
            setNotificationHandler={_SetNotificationHandler}
          />
        )}
        {notification.open && (
          <Notification
            notificationDetails={notification}
            handleClose={() =>
              setNotification({ message: "", severity: "success", open: false })
            }
          />
        )}
      </div>
    </>
  );
}
