// import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = "Page Not Found"; //useRouteError();
  //   console.error(error);

  return (
    <div style={{ position: "fixed", top: "40vh", left: "40vw" }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {/* <i>{error.statusText || error.message}</i>
         */}
        <i>{error}</i>
      </p>
    </div>
  );
}
