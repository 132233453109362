// react
import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import hmacSHA512 from "crypto-js/hmac-sha512";
// config
import { getIp } from "config/helper";
import { NOTIFICATION } from "config/config";
import { TOKEN } from "config/config";
import { addCaptchaToLocalStorage } from "config/helper";
// animation
import SendingMsgAnimation from "assets/animations/Animation - SendingMsg.json";
// assets
import styles from "components/Contact/assets/sass/Contact.module.sass";
import DotLottie from "components/Contact/DotLottie";

interface ContactProps {
  ToggleContactUsModalHandler: () => void;
  setNotificationHandler: (details: NOTIFICATION) => void;
}
interface FormValues {
  name: string;
  email: string;
  message: string;
}
const Contact = (props: ContactProps) => {
  const { ToggleContactUsModalHandler, setNotificationHandler } = {
    ...props,
  };
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((previousValues) => ({
      ...previousValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const signRequest = (
    url: string,
    method: string = "POST",
    timestamp: string
  ) => {
    const secretKey = TOKEN;

    const signature = hmacSHA512(url + method + timestamp, secretKey);
    return signature.toString();
  };
  const behaviour = async (returned: any) => {
    let notificationDetails: NOTIFICATION = {
      severity: "success",
      message: "",
      open: false,
    };
    if (returned.flag === "TRACKING") {
      if (returned.message[0] === 403) {
        notificationDetails = {
          severity: "error",
          message:
            "Service cap reached today! Take a breather and come back for more fun tomorrow!",
          open: true,
        };
      } else if (returned.message[0] === 303) {
        window.location.href = "../Geo/verify";
      }
    } else if (returned.flag === "SUCCESS") {
      notificationDetails = {
        message: "Email sent successfully.",
        severity: "success",
        open: true,
      };
    } else if (returned.flag === "ERROR") {
      notificationDetails = {
        message: returned.message[0]
          ? returned.message[0]
          : "Can't send message.",
        severity: "error",
        open: true,
      };
    }
    setNotificationHandler(notificationDetails);
    setIsLoading(false);
  };

  const handleValidation = () => {
    const newErrors: Partial<FormValues> = {};

    if (!formValues.name.trim()) {
      newErrors.name = "Name is required.";
    }

    if (!formValues.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      newErrors.email = "Invalid email address.";
    }
    if (!formValues.message.trim()) {
      newErrors.message = "Message is required.";
    } else if (formValues.message.trim().length < 15) {
      newErrors.message = "Message must be at least 15 characters.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return false;
    }
    return true;
  };
  const submitHandler = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    const apiURL = process.env.REACT_APP_API_URL || "";
    const endpointUrl = "contact/";

    const valid = handleValidation();
    if (!valid) return;
    console.log(apiURL + endpointUrl);
    let returned = { flag: "", message: [""] };

    const timestamp = new Date().toISOString();
    const signature = signRequest("/" + endpointUrl, "POST", timestamp);

    const ip = await getIp();

    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("email", formValues.email);
    formData.append("message", formValues.message);
    formData.append("ip", ip);

    try {
      await axios
        .post(apiURL + endpointUrl, formData, {
          headers: {
            "X-Signature": signature,
            "X-Timestamp": timestamp,
          },
        })

        .then((response: AxiosResponse) => {
          console.log(response);
          if (response.data.verification_status) {
            if (response.data.captcha) {
              addCaptchaToLocalStorage(response.data.captcha);
              returned = {
                flag: "TRACKING",
                message: [response.data.verification_status],
              };
            } else {
              returned = {
                flag: "TRACKING",
                message: [response.data.verification_status],
              };
            }
          } else if (response.data === "Email sent successfully.") {
            returned = { flag: "SUCCESS", message: response.data };
          } else {
            returned = { flag: "ERROR", message: ["Error occured."] };
          }
        })
        .catch((error: Error) => {
          returned = { flag: "ERROR", message: ["Error occured."] };
        });
    } catch (error) {
      returned = { flag: "ERROR", message: ["Error occured."] };
    }
    await behaviour(returned);
    ToggleContactUsModalHandler();
  };

  return (
    <>
      <div
        className={`${styles.modalBackdrop} fixed flex inset-0 w-full h-screen mx-auto cursor-default align-middle justify-center items-center backdrop-blur-sm`}
      >
        <div
          className={`${styles.main} flex modal justify-center items-center p-6`}
        >
          <form onSubmit={submitHandler}>
            <div
              id="container"
              className={`${styles.container} bg-gray-form w-full text-base p-6 text-center align-middle shadow-xl transition-all transform overflow-hidden`}
            >
              <div className="text-2xl font-semibold text-white leading-10 mt-1">
                Welcome to GISimplify
              </div>
              <div className="text-xl font-medium text-white leading-10 mt-1 text-left">
                Contact us
              </div>
              <div className="text-xs font-thin leading-4 mt-1 text-gray-label text-left">
                Get in Touch for Support, Inquiries, or Any Questions You Might
                Have.
                <br />
                We're Here to Help and Ready to Listen!
              </div>
              <div
                className={`${styles.subContainer} flex justify-between items-start w-full mt-10 lg:pt-1`}
              >
                <div className={`${styles.inputContainer}`}>
                  <label className="block text-xs font-medium mr-2 ml-1 text-gray-label">
                    Your Name:
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    className={`${
                      errors.name ? styles.inputError : styles.input
                    } bg-gray-input-bck autofill-bg-gray-input-bck shadow-custom max-w-full mt-1 -mb-1.5 border-current pl-2 pr-12 w-full h-10 focus:outline-custom-gray-outline focus:ring-gray-100 text-white text-xsm font-thin`}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <span className={styles.helper}>{errors.name}</span>
                  )}
                </div>
                <div className={styles.inputContainer}>
                  <label className="block text-xs font-medium mr-2 ml-1 text-gray-label">
                    Your Email:
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                    className={`${
                      errors.email ? styles.inputError : styles.input
                    } bg-gray-input-bck autofill-bg-gray-input-bck shadow-custom max-w-full mt-1 -mb-1.5 border-current pl-2 pr-12 w-full h-10 focus:outline-custom-gray-outline focus:ring-gray-100 text-white text-xsm font-thin`}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className={styles.helper}>{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="flex flex-col lg:h-41 mt-3 lg:mt-0 lg:pt-3.25 text-left">
                <label className="block text-xs font-medium mr-2 ml-1 text-gray-label">
                  Your Message:
                </label>
                <textarea
                  placeholder="Enter your message"
                  name="message"
                  className={`${
                    errors.message ? styles.inputError : styles.input
                  } bg-gray-input-bck autofill-bg-gray-input-bck shadow-custom max-w-full mt-1 mb-0 resize-none border-current pl-2 pt-1.5 h-28 focus:outline-custom-gray-outline focus:ring-gray-100 text-white text-xsm font-thin`}
                  style={{ width: "32.85rem" }}
                  onChange={handleChange}
                />
                {errors.message && (
                  <span className={styles.helper}>{errors.message}</span>
                )}
              </div>
              <div
                className={`${styles.buttons} flex items-center justify-between m-auto mt-7 mb-2`}
              >
                <button
                  type="submit"
                  className={`${styles.button} w-36 h-7 shadow-md flex items-center justify-center border border-custom-red-button bg-custom-red-button px-6 py-1 text-xsm font-normal text-white hover:text-xsm hover:font-normal hover:bg-custom-red hover:border-2 hover:border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-100 focus-visible:ring-offset-2`}
                >
                  Send
                </button>
                <button
                  type="button"
                  onClick={ToggleContactUsModalHandler}
                  className={`${styles.button} w-36 h-7 shadow-md flex items-center justify-center border border-custom-red-button bg-custom-red-button px-6 py-1 text-xsm font-normal text-white hover:text-xsm hover:font-normal hover:bg-custom-red hover:border-2 hover:border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-100 focus-visible:ring-offset-2`}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        {isLoading && (
          <div className={styles.loader}>
            <DotLottie animation={SendingMsgAnimation} />
          </div>
        )}
      </div>
    </>
  );
};

export default Contact;
