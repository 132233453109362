import Lottie from "lottie-react";

interface DotLottieProps {
  animation: {};
}
const DotLottie = ({ animation }: DotLottieProps) => {
  return (
    <>
      <Lottie
        animationData={animation}
        loop={true}
        autoplay={true}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        style={{
          width: window.innerWidth > 600 ? 400 : 250,
          height: window.innerWidth > 600 ? 400 : 250,
          position: window.innerWidth > 600 ? "static" : "absolute",
          top: window.innerWidth > 600 ? 0 : "17rem",
        }}
      />
    </>
  );
};

export default DotLottie;
