// components
import Container from "../Containers/Container";

// assets
import { WebMapsIcon } from "assets/icons";
import CloudImage1 from "assets/images/cloud-1.png";
import CloudImage2 from "assets/images/cloud-2.png";

interface IProps {
  ToggleContactUsModalHandler: () => void;
}

export default function Cloud(props: IProps) {
  // tracking contact modal
  const { ToggleContactUsModalHandler } = {
    ...props,
  };
  return (
    <Container>
      <div className="pt-48 px-4 md:px-0 relative max-w-[1800px] mx-auto 2xl:pl-16 flex items-center justify-center">
        <div className="absolute top-48 left-0 w-full h-[250px] sm:h-[350px] lg:h-[550px] bg-[rgb(217_217_217)]" />

        <div className="w-full pt-20 flex flex-col items-center">
          <div className="relative z-[1]">
            <img
              src={CloudImage1}
              alt="..."
              className="absolute inset-0 aspect-[16/9] z-[-1]"
            />
            <img
              src={CloudImage2}
              alt="..."
              className="aspect-[16/9] transition-opacity duration-1000 ease-in-out hover:opacity-0"
            />
          </div>

          <div className="pt-11 relative text-center">
            <WebMapsIcon className="w-[313px] h-[25px] md:w-[718px] md:h-[46px] absolute -top-1 left-1/2 -translate-x-1/2" />
            <h3 className="uppercase text-xl 2xl:text-[26px] leading-none font-medium text-primary-dark">
              Cloud-based software to create & share
            </h3>
            <h2 className="uppercase text-5xl 2xl:text-[55px] leading-none font-bold text-primary">
              interactive web maps
            </h2>
            <p className="mt-7 text-base text-primary-dark max-w-[960px]">
              GISimplify: where data visualization becomes a piece of cake! With
              our help, you'll not only be able to see your data clearly, but
              also store and retrieve it effortlessly. Plus, our ability to
              pinpoint each feature and see its attributes will make
              decision-making a breeze. Let us make data management simple for
              you
            </p>
            <button
              onClick={ToggleContactUsModalHandler}
              className="mt-8 w-fit py-5 px-7 text-sm lg:text-base font-bold text-[#F5F5F5] uppercase bg-primary transition-colors hover:bg-primary-dark"
            >
              Request a Demo
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
