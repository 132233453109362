/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import classnames from "classnames";

// assets
import { BurgerMenuIcon, LogoIcon, XIcon } from "assets/icons";

interface INavbarProps {}
export default function Navbar(props: INavbarProps) {
  // tracking menu
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const {} = {
    ...props,
  };

  return (
    <Fragment   >
      <div className="flex items-center" style={{backgroundColor:"white"}}>
      <a href="../">

        <div className="w-[180px] h-[60px] lg:w-[280px] lg:h-[120px] flex items-center justify-center bg-primary">
          <LogoIcon                    style={{ zIndex: "1000" }}
className="w-[100px] h-[30px] lg:w-[200px] lg:h-[60px]" />
        </div>
</a>
        <div  className="pl-16 hidden lg:flex items-center gap-8">
          <a
            href="/"
            className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          >
            Home
          </a>
          <a
            href="../#services"
            className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          >
            Our Services
          </a>
          <a
            href="../#portfolio"
            className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          >
            Portfolio
          </a>
          <a
            href="../#link"
            className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          >
            Links
          </a>
          <a
            href="../#contact"
            className="cursor-pointer text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          >
            Contact Us
          </a>
        </div>

        <div className="ml-auto pr-4 min-[1380px]:pr-20 hidden lg:flex items-center gap-5">
          <a
            href="../billpay"
            className="text-base font-medium text-primary-dark transition-colors hover:text-primary hover:cursor-pointer hover:underline"
          >
            Pay Bill
          </a>
          <a href="../Geo" target="_blank" rel="noreferrer">
            <button className="hidden xl:inline-block py-5 px-7 text-base font-bold text-[#F5F5F5] uppercase bg-primary transition-colors hover:bg-primary-dark">
              Access Web Map
            </button>
          </a>
        </div>

        <div className="block lg:hidden ml-auto pr-4">
          <BurgerMenuIcon                   style={{ zIndex: "1000" }}
 className="cursor-pointer" onClick={handleOpen} />
        </div>

        <NavMenus  isOpen={isOpen} handleClose={handleClose} />
      </div>
    </Fragment>
  );
}

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

function NavMenus({ isOpen, handleClose }: IProps) {
  return (
    <div
      className={classnames(
        "fixed inset-0 bg-[#F5F5F5] translate-x-[100%] z-50 flex flex-col flex-grow p-6 transition-transform duration-500",
        {
          "translate-x-0": isOpen,
        }
      )}
    >
      <XIcon                   style={{ zIndex: "1000" }}

        className="w-7 h-7 inline-block ml-auto cursor-pointer"
        onClick={handleClose}
      />

      <div className="flex flex-col gap-4 items-center">
        <a
          href="../"
          className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
        >
          Home
        </a>
        <a
          href="../#services"
          className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          onClick={handleClose}
        >
          Our Services
        </a>
        <a
          href="../#portfolio"
          className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          onClick={handleClose}
        >
          Portfolio
        </a>
        <a
          href="../#link"
          className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:underline"
          onClick={handleClose}
        >
          Links
        </a>
        <a
          href="../#contact"
          className="text-base font-semibold text-primary-dark transition-colors hover:text-primary hover:cursor-pointer hover:underline"
          onClick={handleClose}
        >
          Contact Us
        </a>
        <a
          href="../billpay"
          className="text-base font-medium text-primary-dark transition-colors hover:text-primary hover:cursor-pointer hover:underline"
        >
          Pay Bill
        </a>
      </div>
    </div>
  );
}
