import * as React from "react";

function Youtube(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 11 7" fill="none" {...props}>
      <path
        d="M10.878 3.307s0 1.538-.211 2.275a1.146 1.146 0 01-.329.522 1.28 1.28 0 01-.567.298c-.789.198-3.956.198-3.956.198s-3.166 0-3.956-.198a1.281 1.281 0 01-.567-.298 1.147 1.147 0 01-.329-.522C.751 4.845.751 3.307.751 3.307s0-1.537.212-2.274c.057-.2.17-.382.327-.529.157-.146.353-.252.569-.306C2.649 0 5.815 0 5.815 0s3.167 0 3.956.198c.216.054.412.16.569.306.157.147.27.33.327.53.211.736.211 2.273.211 2.273zm-3.452 0L4.78 1.911v2.792l2.647-1.396z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Youtube;
