// components
import Container from "../Containers/Container";

// assets
import { ArrowLeftIcon, ArrowRightIcon, PortfolioIcon } from "assets/icons";
import PortfolioImage1_1 from "assets/images/portfolio-1.1.png";
import PortfolioImage1_2 from "assets/images/portfolio-1.2.png";
import PortfolioImage2_1 from "assets/images/portfolio-2.1.png";
import PortfolioImage2_2 from "assets/images/portfolio-2.2.png";
import PortfolioImage3_1 from "assets/images/portfolio-3.1.png";
import PortfolioImage3_2 from "assets/images/portfolio-3.2.png";
import PortfolioImage4_1 from "assets/images/portfolio-4.1.png";
import PortfolioImage4_2 from "assets/images/portfolio-4.2.png";

import { useState } from "react";

const portfolioPages = 2;
const column1Portfolio = [
  {
    href: "https://services.agriculture.pa.gov/slfreport/",
    url1: PortfolioImage1_1,
    url2: PortfolioImage1_2,
  },
  {
    href: "/Geo",
    url1: PortfolioImage2_1,
    url2: PortfolioImage2_2,
  },
];

const column2Portfolio = [
  {
    href: "/Geo",
    url1: PortfolioImage2_1,
    url2: PortfolioImage2_2,
  },
  {
    href: "https://geonomics.us/",
    url1: PortfolioImage3_1,
    url2: PortfolioImage3_2,
  },
];

const column3Portfolio = [
  {
    href: "https://geonomics.us/",
    url1: PortfolioImage3_1,
    url2: PortfolioImage3_2,
  },
  {
    href: "https://gis.mara.gov.om/awqaf-dashboard/",
    url1: PortfolioImage4_1,
    url2: PortfolioImage4_2,
  },
];
export default function Portfolio() {
  const [portfolioPage, setPortfolioPage] = useState(0);
  const _PortfolioNavigatorClickHandler = (pPageCounter: number) => {
    setPortfolioPage((prev) => {
      let pageIndex = prev + pPageCounter;
      if (pageIndex < 0) {
        return 0;
      } else if (pageIndex > portfolioPages - 1) {
        return portfolioPages - 1;
      } else {
        return pageIndex;
      }
    });
  };
  return (
    <Container>
      <div
        id="portfolio"
        className="pt-[5.5rem] 2xl:pt-[6.5rem] px-4 lg:px-0 flex flex-col-reverse lg:flex-row lg:items-center gap-10 xl:gap-20"
      >
        <div className="flex flex-col items-center md:items-stretch gap-2 md:gap-0 md:flex-row">
          <a
            href={column1Portfolio[portfolioPage].href}
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative">
              <img
                src={column1Portfolio[portfolioPage].url2}
                alt="..."
                className="absolute inset-0 aspect-[4/6] xl:aspect-[3/4] z-[-1]"
              />
              <img
                src={column1Portfolio[portfolioPage].url1}
                alt="..."
                className="aspect-[4/6] xl:aspect-[3/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
          </a>
          <a
            href={column2Portfolio[portfolioPage].href}
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative">
              <img
                src={column2Portfolio[portfolioPage].url2}
                alt="..."
                className="absolute inset-0 aspect-[4/6] xl:aspect-[3/4] z-[-1]"
              />
              <img
                src={column2Portfolio[portfolioPage].url1}
                alt="..."
                className="aspect-[4/6] xl:aspect-[3/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
          </a>
          <a
            href={column3Portfolio[portfolioPage].href}
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative">
              <img
                src={column3Portfolio[portfolioPage].url2}
                alt="..."
                className="absolute inset-0 aspect-[4/6] xl:aspect-[3/4] z-[-1]"
              />
              <img
                src={column3Portfolio[portfolioPage].url1}
                alt="..."
                className="aspect-[4/6] xl:aspect-[3/4] transition-opacity duration-1000 ease-in-out hover:opacity-0"
              />
            </div>
          </a>
        </div>

        <div className="relative pr-4 xxl-1900:pr-0">
          <h2 className="text-3xl md:text-[2.625rem] font-bold text-primary-dark leading-none uppercase whitespace-pre-line">
            Our <span className="block text-primary">Portfolio</span>
          </h2>
          <p className="mt-5 max-w-[440px] text-base text-primary-dark font-normal capitalize">
            Feast your eyes on our impressive portfolio! We've got it all: from
            Enterprise Architecture to Custom Web-Desktop-Mobile Apps, from
            ArcMap-ArcPro Addins to Scripts and Automation, from Dashboards
            to... well, you name it! We've got a little bit of everything and
            we're always adding more. So, don't be shy, take a peek and see what
            we can do for you!
          </p>
          <div className="mt-10  xxl-1900:-mr-16 flex items-center justify-end gap-2">
            <span
              onClick={() => {
                _PortfolioNavigatorClickHandler(1);
              }}
              className={`${
                portfolioPage == 0 ? "bg-primary" : "bg-[#D9D9D9]"
              } w-8 xl:w-12 h-8 xl:h-12 rounded-full flex items-center justify-center text-white  cursor-pointer transition-colors hover:bg-primary-dark`}
            >
              <ArrowLeftIcon />
            </span>
            <span
              onClick={() => {
                _PortfolioNavigatorClickHandler(-1);
              }}
              className={`${
                portfolioPage > 0 ? "bg-primary" : "bg-[#D9D9D9]"
              } w-8 xl:w-12 h-8 xl:h-12 rounded-full flex items-center justify-center text-white cursor-pointer transition-colors hover:bg-primary-dark`}
            >
              <ArrowRightIcon />
            </span>
          </div>

          <PortfolioIcon className="w-[249px] h-[25px] xl:w-[400px] xl:h-[50px]  absolute bottom-0 right-0 xxl-1900:-right-28 z-[-1]" />
        </div>
      </div>
    </Container>
  );
}
