interface BankDetailComponentProps {}

const BankDetailComponent = (props: BankDetailComponentProps) => {
  return (
    <div>
      <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
        Payment Details
      </span>
      <div style={{ textAlign: "left" }}>
        <div>
          <span style={{ fontWeight: "bold" }}>Name:</span>
          <span style={{ fontSize: "13px" }}> GISimplify</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Address:</span>
          <span style={{ fontSize: "13px" }}>
            128 Winner St, Acworth, GA, 30102
          </span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>SWIFT/BIC:</span>{" "}
          <span style={{ fontSize: "13px" }}> CHASUS33</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Account number:</span>{" "}
          <span style={{ fontSize: "13px" }}> 995287320</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Bank Name:</span>{" "}
          <span style={{ fontSize: "13px" }}> JPMORGAN CHASE BANK, N.A.</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Bank Address:</span>
          <span style={{ fontSize: "13px" }}>
            {" "}
            383 MADISON AVENUE, NEW YORK, NY, 10017
          </span>
        </div>
      </div>
    </div>
  );
};
export default BankDetailComponent;
