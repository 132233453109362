// components
import Container from "../Containers/Container";

// assets
import { ArrowLeftIcon, ArrowRightIcon, OurServicesIcon } from "assets/icons";
import ServiceImage1 from "assets/images/services-1.png";
import ServiceImage2 from "assets/images/services-2.png";
import ServiceImage3 from "assets/images/services-3.png";
import consultation from "assets/images/consultation.jpg";

import { useState } from "react";

const servicePagesCount = 2;

const clmn1Services = [
  {
    title: "Software Development",
    description:
      "Transform your ideas into successful products with our expert guidance. From discovery to launch, we'll definitely make it happen.",
    img: ServiceImage1,
  },
  {
    title: "Spatial Services",
    description:
      "Elevate your decision making with our expert blend of spatial info, analytics, and location intelligence. Let us help you soar to new heights.",
    img: ServiceImage2,
  },
];

const clmn2Services = [
  {
    title: "Spatial Services",
    description:
      "Elevate your decision making with our expert blend of spatial info, analytics, and location intelligence. Let us help you soar to new heights.",
    img: ServiceImage2,
  },
  {
    title: "Software Development",
    description:
      "Transform your ideas into successful products with our expert guidance. From discovery to launch, we'll definitely make it happen.",
    img: ServiceImage1,
  },
];

const clmn3Services = [
  {
    title: "Enterprise Architecture",
    description:
      "Ready to future-proof your business? We're here to help! From navigating digital transformation to leveraging cutting-edge technology, we'll steer you towards success. Let's stay ahead of the curve together.",
    img: ServiceImage3,
  },
  {
    title: "Consultation",
    description:
      "Unlock Your Full Potential With Our Expert Consultation Services. From Strategic Planning To Implementation, We Provide Tailored Advice And Insights To Drive Your Business Forward. Let’s Achieve Success Together.",
    img: consultation,
  },
];

export default function Services() {
  const [servicePage, setServicePage] = useState(0);
  const _PortfolioNavigatorClickHandler = (pPageCounter: number) => {
    setServicePage((prev) => {
      let pageIndex = prev + pPageCounter;
      if (pageIndex < 0) {
        return 0;
      } else if (pageIndex > servicePagesCount - 1) {
        return servicePagesCount - 1;
      } else {
        return pageIndex;
      }
    });
  };
  return (
    <Container>
      <div id="services" className="relative overflow-hidden">
        <OurServicesIcon className="w-[249px] h-[25px] md-900:w-[498px] md-900:h-[51px] absolute top-[5rem] md:top-[7rem] right-0 z-[-1]" />

        <div className="pt-[5.5rem] 2xl:pt-[6.5rem] px-4 md:px-10 lg:px-20 xl:px-28 2xl:px-40">
          <div className="flex items-center justify-between">
            <h2 className="max-w-none md:max-w-[70%] md-900:max-w-[640px] xl:max-w-[840px] text-3xl md:text-2xl md-900:text-3xl xl:text-[42px] font-bold text-primary-dark uppercase leading-none">
              One Place where you can get your next Project implemented With{" "}
              <span className="text-primary">The Highest Quality.</span>
            </h2>
            <div className="hidden md:flex items-center gap-2">
              <span
                onClick={() => {
                  _PortfolioNavigatorClickHandler(1);
                }}
                className={`${
                  servicePage == 0 ? "bg-primary" : "bg-[#D9D9D9]"
                } w-8 xl:w-12 h-8 xl:h-12 rounded-full flex items-center justify-center text-white  cursor-pointer transition-colors hover:bg-primary-dark`}
              >
                <ArrowLeftIcon />
              </span>
              <span
                onClick={() => {
                  _PortfolioNavigatorClickHandler(-1);
                }}
                className={`${
                  servicePage > 0 ? "bg-primary" : "bg-[#D9D9D9]"
                } w-8 xl:w-12 h-8 xl:h-12 rounded-full flex items-center justify-center text-white cursor-pointer transition-colors hover:bg-primary-dark`}
              >
                <ArrowRightIcon />
              </span>
            </div>
          </div>

          <p className="mt-7 text-base font-normal text-primary-dark max-w-[680px] xl:max-w-[910px]">
            Got an idea worth a million bucks? We'll make it happen! From
            discovery to implementation, we'll guide you on the journey to
            success. Simple or complex, small scale or enterprise-level, we'll
            take it all in stride and craft it into a hit product. Ready, set,
            let's turn that idea into gold!
          </p>

          <div className="mt-12 flex flex-col md:flex-row gap-4">
            {/* cursor-pointer */}
            <div className="md:w-1/3 flex flex-col  shadow-sm md:shadow-none">
              <div
                className="bg-no-repeat bg-cover bg-center aspect-video"
                style={{
                  backgroundImage: `url(${
                    clmn1Services[
                      servicePage >= clmn1Services.length ? 0 : servicePage
                    ].img
                  })`,
                }}
              />
              <div className="pt-3  pl-6 md:pl-0 pb-4 md:pb-0">
                <div className="max-w-[80%] flex items-center justify-between">
                  <h3 className="text-base min-[900]:text-xl 2xl:text-3xl font-semibold leading-tight text-primary-dark capitalize">
                    {
                      clmn1Services[
                        servicePage >= clmn1Services.length ? 0 : servicePage
                      ].title
                    }
                  </h3>
                  {/* <ArrowRightIcon className="w-5 h-5 2xl:w-6 2xl:h-6 text-primary" /> */}
                </div>
                <p className="max-w-[70%] mt-2 text-xs 2xl:text-sm font-normal text-primary-dark capitalize">
                  {
                    clmn1Services[
                      servicePage >= clmn1Services.length ? 0 : servicePage
                    ].description
                  }
                </p>
              </div>
            </div>
            {/* cursor-pointer */}
            <div className="md:w-1/3 flex flex-col md:flex-col-reverse  group shadow-sm md:shadow-none">
              <div
                className="bg-no-repeat bg-cover bg-center aspect-video"
                style={{
                  backgroundImage: `url(${
                    clmn2Services[
                      servicePage >= clmn2Services.length ? 0 : servicePage
                    ].img
                  })`,
                }}
              />
              {/* group-hover:bg-primary-dark */}
              <div className="bg-primary pt-4 pb-4 pl-4 min-[900]:pt-5 min-[900]:pl-6 2xl:pl-12 min-[900]:pb-6 2xl:pb-12 transition-colors ">
                <div className=" max-w-[80%] flex items-center justify-between ">
                  <h3 className="text-base min-[900]:text-xl 2xl:text-3xl font-semibold leading-tight text-white capitalize">
                    {
                      clmn2Services[
                        servicePage >= clmn2Services.length ? 0 : servicePage
                      ].title
                    }
                  </h3>
                  {/* <ArrowRightIcon className="w-5 h-5 2xl:w-6 2xl:h-6 text-white" /> */}
                </div>
                <p className="max-w-[70%] mt-2 text-xs 2xl:text-sm font-normal text-white capitalize">
                  {
                    clmn2Services[
                      servicePage >= clmn2Services.length ? 0 : servicePage
                    ].description
                  }
                </p>
              </div>
            </div>
            {/* cursor-pointer */}
            <div className="md:w-1/3 flex flex-col shadow-sm md:shadow-none">
              <div
                className="bg-no-repeat bg-cover bg-center aspect-video"
                style={{
                  backgroundImage: `url(${
                    clmn3Services[
                      servicePage >= clmn3Services.length ? 0 : servicePage
                    ].img
                  })`,
                }}
              />
              <div className="pt-3 pl-6 2lx:pl-12 pb-4 md:pb-0">
                <div className="max-w-[80%] flex items-center justify-between ">
                  <h3 className="text-base min-[900]:text-xl 2xl:text-3xl font-semibold leading-tight text-primary-dark capitalize">
                    {
                      clmn3Services[
                        servicePage >= clmn3Services.length ? 0 : servicePage
                      ].title
                    }
                  </h3>
                  {/* <ArrowRightIcon className="w-5 h-5 2xl:w-6 2xl:h-6 text-primary" /> */}
                </div>
                <p className="max-w-[70%] text-xs 2xl:text-sm font-normal text-primary-dark capitalize">
                  {
                    clmn3Services[
                      servicePage >= clmn3Services.length ? 0 : servicePage
                    ].description
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
