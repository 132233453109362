import classNames from "classnames";

// components
import Container from "../Containers/Container";

// assets
import { LogoIcon, HalfGlobeIcon } from "assets/icons";
import MacBook from "assets/images/macbook.png";
import MacCover1 from "assets/images/mac-cover-1.png";

export default function CTA() {
  return (
    <div className="bg-primary mt-[5.5rem] 2xl:mt-[6.5rem]">
      <Container wrapperClassname="relative">
        <div className="pt-14 xl:pt-28 px-4 md:px-0 flex flex-col-reverse xl:flex-row items-center xl:items-start justify-center gap-6 xl:gap-0">
          <div className="relative -mb-6 sm:-mb-10 flex-shrink-0">
            <img src={MacBook} alt="..." className="" />
            <div className="w-[74.5%] h-[74.5%] absolute top-[4.6%] left-[13%] flex items-center justify-center cursor-pointer group">
              <LogoIcon className="w-[206px] h-[64px] sm:w-[412px] sm:h-[128px] relative z-[20]" />
              <div
                className={classNames(
                  "bg-no-repeat bg-cover bg-center absolute inset-0 z-[10]",
                  "before:content[''] before:absolute before:inset-0 before:bg-black before:bg-opacity-80 transition-all group-hover:before:bg-primary group-hover:before:opacity-100"
                )}
                style={{
                  backgroundImage: `url(${MacCover1})`,
                }}
              />
            </div>
          </div>

          <div className="max-w-[580px]">
            <h2 className="text-3xl md:text-[2.625rem] text-white leading-tight">
              With us. It's Simpler.
            </h2>
            <p className="mt-2.5 text-base font-normal text-white">
              Say goodbye to GIS confusion! We're here to simplify the process
              and make Geographic Information Systems easier to understand,
              design, and integrate. From developing apps to creating highly
              available systems, we'll make it a breeze. Our mission is to
              ensure your success and make every step crystal clear. With our
              robust, cost-effective solutions, we'll tackle your business needs
              with ease. Let's make GIS simple!
            </p>

            <a
              href="https://www.esri.com/partners/gisimplify-a2T5x000008VY6bEAG"
              target="_blank"
            >
              <button className="mt-8 w-fit py-5 px-7 text-sm lg:text-base font-bold text-[#F5F5F5] uppercase bg-primary-dark transition-colors hover:bg-white hover:text-primary-dark">
                About Us
              </button>
            </a>
          </div>
        </div>

        <HalfGlobeIcon className="hidden 2xl:block absolute w-[473px] h-[370px] bottom-[-3rem] right-0" />
      </Container>
    </div>
  );
}
