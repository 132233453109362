import classnames from "classnames";

// assets
import WorldMapImage from "assets/images/world-map.png";
import { ArrowRightIcon } from "assets/icons";

interface IFooterProps {
  ToggleContactUsModalHandler: () => void;
}

export default function Footer(props: IFooterProps) {
  // tracking contact modal
  const { ToggleContactUsModalHandler } = {
    ...props,
  };

  return (
    <div
      id="contact"
      className={classnames(
        "py-16 relative bg-no-repeat bg-cover bg-center",
        "before:contents[''] before:absolute before:inset-0 before:bg-primary-dark before:mix-blend-multiply before:z-[1]"
      )}
      style={{
        backgroundImage: `url(${WorldMapImage})`,
      }}
    >
      <div className="relative z-10 text-center">
        <div className="px-4 md:px-0">
          <h2 className="text-3xl md:text-[2.625rem] font-bold text-white leading-none uppercase">
            Subscription options for{" "}
            <span className="text-primary">GiSimplify Online</span>
          </h2>
          <p className="mt-4 text-base text-white max-w-[870px] mx-auto">
            Introducing GISimplify web tool, the ultimate online sidekick for
            all your data visualization needs! Whether you're an advanced user
            or a business looking to up your game, we've got you covered. Our
            tool is available to everyone, but for those looking for even more
            capabilities, we've got special subscription plans. Just reach out
            to our sales team and they'll give you the inside scoop on how to
            unlock even more awesomeness!
          </p>
          <button
            className="mt-6 w-fit py-5 px-7 text-sm lg:text-base font-bold text-[#F5F5F5] uppercase bg-primary transition-colors hover:bg-white hover:text-primary-dark"
            onClick={() => {
              ToggleContactUsModalHandler();
            }}
          >
            Contact us for more details
          </button>
        </div>

        <div
          className={classnames(
            "mt-14 py-14 px-4 lg:px-0 bg-[#232321] bg-opacity-60 flex flex-col sm:flex-row justify-center gap-10 lg:gap-20 text-left",
            "before:contents[''] before:border before:border-[#A3A3A3] self-stretch"
          )}
        >
          <div className="order-[-1]">
            <h3 className="text-white text-xl lg:text-2xl xl:text-4xl font-light leading-none capitalize">
              Talk to someone from
            </h3>
            <h3 className="text-primary text-xl lg:text-2xl xl:text-4xl font-light leading-none capitalize">
              our sales team
            </h3>
            <p className="mt-3 text-lg text-white font-normal capitalize">
              +1 (470) 504-3852
            </p>
            <p className="text-lg text-white font-normal capitalize">
              8:00 a.m.–5:00 p.m., Monday through Friday (EST)
            </p>
          </div>

          <div>
            <h3 className="text-white text-xl lg:text-2xl xl:text-4xl font-light leading-none capitalize">
              Contact the sales team
            </h3>
            <h3 className="text-primary text-xl lg:text-2xl xl:text-4xl font-light leading-none capitalize">
              Fill out the contact form
            </h3>
            <p
              className="mt-3 text-lg text-white font-normal capitalize flex items-center gap-4 hover:text-primary hover:underline hover:cursor-pointer"
              onClick={ToggleContactUsModalHandler}
            >
              Click here for Form
              <ArrowRightIcon className="text-primary w-4 h-4" />
            </p>
          </div>
        </div>

        <div className="mt-11 px-4 sm:px-0 flex flex-wrap items-center justify-center gap-6">
          <a
            href="../#en"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            English (Global)
          </a>
          <a
            href="../#services"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Our services
          </a>
          <a
            href="../#portfolio"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Portfolio
          </a>
          <a
            href="../#link"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Links
          </a>
          <a
            href="../Privacy"
            className="text-base font-medium text-white transition-colors hover:text-primary hover:underline"
          >
            Privacy
          </a>
          <a
            href="../#contact"
            className="text-base font-medium text-white cursor-pointer transition-colors hover:text-primary hover:underline"
            onClick={ToggleContactUsModalHandler}
          >
            Contact Us
          </a>
        </div>

        <p className="mt-6 px-4 sm:px-0 text-base font-medium text-white">
          © Copyright 2022. All Rights Reserved. GiSimplify
        </p>
      </div>
    </div>
  );
}
