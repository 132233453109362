// assets
import { FacebookIcon, TwitterIcon, YoutubeIcon } from "assets/icons";

export default function Sidebar() {
  return (
    <div className="w-16 hidden md:flex flex-col items-end pr-2.5">
      <div className="flex flex-col gap-2.5">
        <span className="w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors text-primary-dark  border-[.5px] border-primary-dark hover:bg-primary hover:text-white hover:border-primary">
          <a
            href="https://www.facebook.com/GISimplify"
            target={"_blank"}
            rel={"noreferrer"}
          >
            <FacebookIcon />
          </a>
        </span>
        <span className="w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors text-primary-dark border-[.5px] border-primary-dark hover:bg-primary hover:text-white hover:border-primary">
          <a
            href="https://twitter.com/GISimplify"
            target={"_blank"}
            rel={"noreferrer"}
          >
            <TwitterIcon />
          </a>
        </span>
        <span className="w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors text-primary-dark  border-[.5px] border-primary-dark hover:bg-primary hover:text-white hover:border-primary">
          <a
            href="https://www.youtube.com/@GISimplify"
            target={"_blank"}
            rel={"noreferrer"}
          >
            <YoutubeIcon />
          </a>
        </span>
      </div>
    </div>
  );
}
