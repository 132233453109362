export const getIp = (() => {
  let user_ip = "";

  return async () => {
    if (!user_ip) {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        user_ip = data.ip;
      } catch (error) {}
    }
    console.log(user_ip);
    return user_ip;
  };
})();
export const getCurrentLoggedIn = () => {
  let logged_in = "guest";
  const localStorageData = (localStorage || {}) as Storage;
  Object.keys(localStorageData)?.forEach((key: string) => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        const data = JSON.parse(value);
        try {
          if (data.session_token && data.session_token.status === "logged-in")
            logged_in = key;
        } catch {
          return "guest";
        }
      } catch {}
    }
  });
  return logged_in;
};
export const addCaptchaToLocalStorage = async (guid: string) => {
  const current_user = getCurrentLoggedIn();
  const data = localStorage.getItem(current_user);
  const _info = data !== null ? JSON.parse(data) : null;
  let new_data;
  if (_info) {
    new_data = _info;
    new_data.ip_data.guid = guid;
    new_data.ip_data.map = "0";
  } else {
    new_data = {
      ip_data: {
        ip: await getIp(),
        guid: guid,
        map: "0",
      },
    };
  }
  localStorage.setItem(current_user, JSON.stringify(new_data));
};
